import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { InteractiveDemoForm } from "../components/forms";
import { Container, Wrapper, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import BlurredBR from "../images/graphics/PayHero/PayHeroBlurredx2OPT.png";

const DemoContainer = styled(Container)`
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-height: 1000px;
  background-image: url(${BlurredBR});
  background-size: cover;
  background-repeat: no-repeat;
`;

const DemoForm = styled(Box)`
  position: relative;
  width: 95%;
  max-width: 500px;
  background: ${(props) => props.theme.colours.white};
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  box-shadow: ${(props) => props.theme.styles.shadow};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px;
  }
`;

const PayHeroInteractiveDemo = (props) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.documentElement.classList.remove("-stopScrollMobile");
    }

    // setInputs({
    //   full_name: window.localStorage.getItem("user_name"),
    //   email: window.localStorage.getItem("user_email"),
    // });
  }, []);

  return (
    <Layout fullPage>
      <Seo
        title="Try a Demo | Free Interactive Online Demo | PayHero"
        description="Get a feel for what PayHero has to offer your business right now with our free online interactive demo. Create an account and get 14 days free."
        pathname={props.location.pathname}
      />
      <DemoContainer>
        <Wrapper stackGap={80}>
          <Box stackGap={50}>
            <Link to="/">
              <img
                className="-center"
                src={PayHeroLogo}
                alt="Try a Demo | PayHero | Online Payroll Made Super Easy"
                width={250}
              />
            </Link>
            <DemoForm className="-center" stackGap={30}>
              <div className="-textCenter">
                <h1>Try a Demo</h1>
                <p>Explore PayHero with a fully interactive demo company</p>
              </div>
              <Box>
                <InteractiveDemoForm />
              </Box>
              <Box stackGap={10}>
                <p className="-textCenter">
                  Want to create your own account?{" "}
                  <Link to="/signup">Get started for free</Link>.
                </p>
              </Box>
            </DemoForm>
          </Box>
        </Wrapper>
      </DemoContainer>
    </Layout>
  );
};

export default PayHeroInteractiveDemo;
